/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import facebookImg from "./assets/images/facebook-grey.png"
import instagramImg from "./assets/images/instagram-grey.png"
import youtubeImg from "./assets/images/youtube-grey.png"
import germany from "./assets/images/germany.png"

import "./layout.css"
import "./app.css"

const Layout = ({ children }) => {
  return (
    <div className="app">
      <main>{children}</main>
      <footer>
        <section id="footer-contact" className="py-5 bg-white">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-10 offset-1 col-sm-6 col-md-4 offset-md-0 col-lg-3 d-flex justify-content-sm-center py-3">
                <i className="icon-shopfinder d-flex align-items-center text-black-2"></i>
                <div className="content-wrapper d-flex flex-column justify-content-center">
                  <span className="title text-uppercase text-small text-black-2">Storefinder</span>
                  <a href="https://www.schoeffel.com/de/de/storefinder"
                     className="text-black-2 text-small">Schöffel in
                    deiner Nähe</a>
                </div>
              </div>
              <div
                className="col-10 offset-1 col-sm-6 col-md-4 offset-md-0 col-lg-3 d-flex justify-content-sm-center py-3">
                <i className="icon-mail d-flex align-items-center text-black-2"></i>
                <div className="content-wrapper d-flex flex-column justify-content-center">
                  <span className="title text-uppercase text-small text-black-2">E-Mail</span>
                  <a href="mailto:online@schoeffel.com"
                     className="text-black-2 text-small">online@schoeffel.com</a>
                </div>
              </div>
              <div
                className="col-10 offset-1 col-sm-6 col-md-4 offset-md-0 col-lg-3 d-flex justify-content-sm-center py-3">
                <i className="icon-contact d-flex align-items-center text-black-2"></i>
                <div className="content-wrapper d-flex flex-column justify-content-center">
                  <span className="title text-uppercase text-small text-black-2">Telefon</span>
                  <a href="tel:08007002460" className="text-black-2 text-small">0800-7002460</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
      <section id="footer-navigation" className=" pt-5 pb-0 py-lg-5 imprint">
        <div className="container">
          <div className="row text-white">
            <div className="col-10 col-lg-4 offset-1 offset-lg-0 order-2 order-lg-1 pb-lg-3">
              <ul className="text-uppercase text-small p-0">
                <li className="imprintList"><a className="text-white" href="https://www.schoeffel.com/de/de/faq">FAQ</a>
                </li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/dynamic/returns">Rücksendung</a>
                </li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/dynamic/about_schoeffel">Über
                  uns</a></li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/dynamic/career_overview">Karriere</a>
                </li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/contact">Kontakt</a></li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/article/redirect/giftcard">Geschenkgutscheine</a>
                </li>
              </ul>
            </div>
            <div className="col-10 col-lg-4 offset-1 offset-lg-0 order-3 order-lg-2 pb-lg-3">
              <ul className="text-uppercase text-small p-0">
                <li className="imprintList"><a className="text-white"
                                               href="https://www.schoeffel.com/de/de/catalogue/subscribe">Schöffel
                  Magazin</a></li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/groessentabelle">Größentabelle</a>
                </li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel.com/de/de/dynamic/service_factory">Service
                  Factory</a></li>
                <li className="pt-3 imprintList"><a className="text-white" href="https://pflege.schoeffel.com/">Reinigungs-
                  und Imprägnierservice</a></li>
                <li className="pt-3 imprintList"><a className="text-white" href="https://www.professional-wear.com/">Professional
                  Wear</a></li>
                <li className="pt-3 imprintList"><a className="text-white"
                                                    href="https://www.schoeffel-teamwear.de/">Teamwear</a></li>
              </ul>
            </div>
            <div className="col-10 col-lg-4 offset-1 offset-lg-0 order-6 order-lg-4 mt-4 mt-lg-0 social-wrapper">
              <span className="title d-none d-lg-block text-white text-uppercase text-small">Folge uns</span>
              <div
                className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start flex-wrap pt-5 pt-lg-3 social-distance">
                <a href="https://facebook.com/schoeffel.de/">
                  <div className="image-Container">
                    <img src={facebookImg} className="image-social-small" alt="facebook logo"/>
                  </div>
                </a>
                <a href="https://instagram.com/schoeffel_official/">
                  <div className="image-Container">
                    <img src={instagramImg} className="image-social-normal" alt="Logo" />
                  </div>
                </a>
                <a href="https://youtube.com/user/SchoeffelDE">
                  <div className="image-Container">
                    <img src={youtubeImg} className="image-social-big" alt="youtube logo"/>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright" className="imprint">
        <div className="col-10 offset-1 col-lg-12 offset-lg-0">
          <div className="container pt-3 pb-5 py-md-5 dividing-border">
            <div className="row">
              <div
                className="col-10 offset-1 col-lg-6 offset-lg-0 text-small text-center text-lg-left pb-3 pb-md-0">
                <span className="text-white"> &copy; 2021 Schöffel, Schwabmünchen, Deutschland</span>
              </div>
              <div
                className="col-10 offset-1 col-lg-5 offset-lg-1 d-flex flex-column flex-md-row justify-content-between align-items-center navigation-wrapper">
                <a href="https://www.schoeffel.com/de/de/agb"
                   className="text-uppercase text-small mb-1 mb-md-0 text-grey-1">AGB</a>
                <a href="https://www.schoeffel.com/de/de/impressum"
                   className="text-uppercase text-small mb-1 mb-md-0 text-grey-1">Impressum</a>
                <a href="https://www.schoeffel.com/de/de/datenschutz"
                   className="text-uppercase text-small mb-1 mb-md-0 text-grey-1">Datenschutz</a>
                <div>
                  <img src={germany} className="image-country" alt="german flag"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
